import React from 'react';
import { Switch, Route } from 'react-router-dom';

import LandingPage from './landingpage';
import Privacy from './privacy';
import Terms from './terms';
import Download from './download';
import Payments from './payments';
import GuestList from './guestlist';


const Main = () => (
  <Switch>
    <Route exact path="/" component={LandingPage} />
    <Route path="/privacy" component={Privacy} />
    <Route path="/terms" component={Terms} />
    <Route path="/download" component={Download} />
    <Route path="/payments/:party/:user" component={Payments} />
    <Route path="/guestlist/:party" component={GuestList} />
  </Switch>
)

export default Main;
