import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';
// import { Link, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

import { PayPalButton } from "react-paypal-button-v2";

import moment from 'moment';

import Loading from './loader.js'

import app from 'firebase/app';
import firebase from 'firebase';

ReactGA.initialize('UA-146114575-2');
ReactGA.pageview('/download');

class Payments extends Component {

  constructor(props) {
    super(props);
    this.state = {
      partyName:"",
      loading: true,
      invalidUser: false,
      invalidParty: false,
      transactionComplete: false,
      alreadyPaid: false,
    };
  }

  componentDidMount = async () => {
    await this.initializeFirebase();
    //PROBS worth adding a check in here after valid user to make sure the person hasn't already paid their cover
    this.checkValidUser();
  }


  initializeFirebase = async () => {
    const config = {
      apiKey: "AIzaSyBTDpAEjShenstwm3kytuuv4GKcfDlyjdg",
      authDomain: "circle-reactnative.firebaseapp.com",
      databaseURL: "https://circle-reactnative.firebaseio.com",
      projectId: "circle-reactnative",
      storageBucket: "circle-reactnative.appspot.com",
      messagingSenderId: "194697646030",
      appId: "1:194697646030:web:bd7a7b5a6a06616819259f",
      measurementId: "G-RZMFRTX2Z2"
    };

    await app.initializeApp(config);
  }

  checkValidUser = async () => {
    var userId = this.props.match.params.user;

    const db = firebase.firestore();

    let userRef = db.collection('users').doc(userId);
    let getDoc = userRef.get()
      .then(doc => {
        if (!doc.exists) {
          //No matching user
          console.log('No such user could be found!');
          this.setState({
            invalidUser:true,
            loading: false,
          })
        } else {
          //User found
          console.log('User found');
          this.getPartyDetails();
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });
  }

  getPartyDetails = async () => {
    //Get party details using partyId
    var partyId = this.props.match.params.party;
    var userId = this.props.match.params.user;

    const db = firebase.firestore();

    let partyRef = db.collection('parties').doc(partyId);
    let getDoc = partyRef.get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such party could be found!');
          this.setState({
            invalidParty:true,
            loading: false,
          })
        } else {
          console.log('Party found');

          //Calculate the service fees and total cost
          //2.9% + $0.30 per sale + extra 5 cents
          var ticketPrice = Number((doc.data().ticketPrice).toFixed(2));
          var ticketFees = Number((ticketPrice * 0.029 + 0.30 + 0.02).toFixed(2));

          //Turn timestamp into a readable string
          var timestamp = doc.data().time;
          var partyTimeStringDate = moment(timestamp).format("ddd, MMM Do");
          var partyTimeStringTime = moment(timestamp).format("h:mm a");
          var partyTimeString = partyTimeStringDate + " at " + partyTimeStringTime;

          //Timestamp for party description
          var paymentTimeString = moment(timestamp).format("D MMM");

          //Generate payment description
          var partyTitle = doc.data().title;
          var paymentDescription = "Cover charge for " + partyTitle + " on " + paymentTimeString;

          this.setState({
            partyName: doc.data().title,
            partyHost: doc.data().host,
            ticketFees: ticketFees,
            ticketPrice: ticketPrice,
            totalCost: Number((ticketPrice + ticketFees).toFixed(2)),
            partyDate: partyTimeString,
            paymentDescription: paymentDescription,
          })

          this.checkUserPaid();
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });
  }

  checkUserPaid = async () => {
    var partyId = this.props.match.params.party;
    var userId = this.props.match.params.user;

    const db = firebase.firestore();

    let that = this;

    db
    .collection("parties").doc(partyId)
    .collection("attending").doc(userId)
      .get()
      .then(function(querySnapshot) {
        var userPaid = querySnapshot.data().paid
        if (userPaid){
          //User has already paid
          console.log('User has already paid')
          that.setState({loading:false, alreadyPaid:true})
        }else{
          //User has not paid
          console.log('User has not already paid')
          that.setState({loading:false})
        }
    });
  }

  markUserPaid = async (data) => {
    var partyId = this.props.match.params.party;
    var userId = this.props.match.params.user;
    var timenow = moment().format('x');

    const db = firebase.firestore();

    //Update total payments received for party
    var paidAmount = this.state.ticketPrice;
    var paidFees = this.state.ticketFees;

    //Update attending list and add payment details
    await db
    .collection("parties").doc(partyId)
    .collection("attending").doc(userId)
    .set({
        paid: true,
        paidTime: Number(timenow),
        paidAmount: Number(data.purchase_units[0].amount.value),
        paidAmountTicket: this.state.ticketPrice,
        paidAmountFees: this.state.ticketFees,
        paidTransactionId: data.id,
        paidPayerId: data.payer.payer_id,
    }, { merge: true });

    await db
    .collection("parties").doc(partyId)
    .set({
    paymentsReceivedCost: firebase.firestore.FieldValue.increment(paidAmount),
    paymentsReceivedCount: firebase.firestore.FieldValue.increment(1),
    }, { merge: true });


    //Write payments to independent payments collection
    await db
    .collection("payments").doc(partyId)
    .collection("paymentsReceived").doc(userId)
    .set({
        paid: true,
        paidTime: Number(timenow),
        paidAmount: Number(data.purchase_units[0].amount.value),
        paidAmountTicket: this.state.ticketPrice,
        paidAmountFees: this.state.ticketFees,
        paidTransactionId: data.id,
        paidPayerId: data.payer.payer_id,
        userId: this.props.match.params.user,
    }, { merge: true });

    await db
    .collection("payments").doc(partyId)
    .set({
    paymentsReceivedCost: firebase.firestore.FieldValue.increment(paidAmount),
    paymentsReceivedFees: firebase.firestore.FieldValue.increment(paidFees),
    paymentsReceivedCount: firebase.firestore.FieldValue.increment(1),
    }, { merge: true });


    this.setState({
      paidAmount: data.purchase_units[0].amount.value,
      paidTransactionId: data.id,
      transactionComplete:true,
      loading:false,
    })
  }


  renderButtons(){
    return(
      <div>
        <a href="circleparties://">
          <div class="payments-view-wrapper-button">
            <p class="payments-text-button">Return to app</p>
          </div>
        </a>

        <a href="mailto:support@circleparties.com?" class="payments-text-button-lite">
          <div class="payments-view-wrapper-button-lite">
            <p class="payments-text-button-lite">Contact Support</p>
          </div>
        </a>

      </div>
    )
  }

  renderContent(){
    if (this.state.loading){
      return(
        <div className="guestlist-loading-wrapper">
          <div className="guestlist-loading">
              <Loading />
          </div>
        </div>
      )
    }else{
      if (this.state.invalidUser || this.state.invalidParty){
        //Error occured - ideally send an email straight away to the support email with partyId and userId
        var partyId = this.props.match.params.party;
        var userId = this.props.match.params.user;

        return(
          <Cell col={12} className="vertical">
            <div class="payments-logo">
              <img
              src="https://firebasestorage.googleapis.com/v0/b/circle-reactnative.appspot.com/o/circle-cup.png?alt=media&token=a6007a2e-183c-4681-bf1a-07ea637d1d2b"
              height="50"
              alt="Circle Logo"
              />
            </div>

            <div>
              <p class="payments-text-partyName">Uh oh</p>
              <p class="payments-text-partySubtitle">Something went wrong...</p>
            </div>

            <div class="payments-view-wrapper">
              <p class="payments-text-partySubtitle">The team's been notified and will look into it ASAP</p>
            </div>
          </Cell>
        )

      }else{

        if (this.state.alreadyPaid){
          return(
            <Cell col={12} className="vertical">
              <div class="payments-view-wrapper">
                <div class="payments-logo">
                  <img
                  src="https://firebasestorage.googleapis.com/v0/b/circle-reactnative.appspot.com/o/circle-cup.png?alt=media&token=a6007a2e-183c-4681-bf1a-07ea637d1d2b"
                  height="50"
                  alt="Circle Logo"
                  />
                </div>

                <div>
                  <p class="payments-text-partyName">Hold up!</p>
                  <p class="payments-text-partySubtitle">You're already marked as paid for this party!</p>
                </div>
              </div>

              {this.renderButtons()}

            </Cell>
          )
        }else{
          if (this.state.transactionComplete){
            return(
              <Cell col={12} className="vertical">
                <div class="payments-logo">
                  <img
                  src="https://firebasestorage.googleapis.com/v0/b/circle-reactnative.appspot.com/o/circle-cup.png?alt=media&token=a6007a2e-183c-4681-bf1a-07ea637d1d2b"
                  height="50"
                  alt="Circle Logo"
                  />
                </div>

                <div>
                  <p class="payments-text-partyName">Payment complete!</p>
                </div>

                <div class="payments-view-wrapper-2">

                  <div class='payments-table-wrapper'>
                    <div class="payments-table-title">Ref</div>
                    <div class="payments-table-value">{this.state.paidTransactionId}</div>
                  </div>

                  <div class='payments-table-wrapper'>
                    <div class="payments-table-title">Total</div>
                    <div class="payments-table-value">${this.state.paidAmount}</div>
                  </div>

                  <div class='payments-table-wrapper'>
                    <div class="payments-table-title">Party</div>
                    <div class="payments-table-value">{this.state.partyName}</div>
                  </div>

                  <div class='payments-table-wrapper'>
                    <div class="payments-table-title">Host</div>
                    <div class="payments-table-value">{this.state.partyHost}</div>
                  </div>

                  <div class='payments-table-wrapper'>
                    <div class="payments-table-title">Starts</div>
                    <div class="payments-table-value">{this.state.partyDate}</div>
                  </div>

                </div>

                {this.renderButtons()}

              </Cell>
            )
          }else{
            return(
              <Cell col={12} className="vertical">

                <div class="payments-logo">
                  <img
                  src="https://firebasestorage.googleapis.com/v0/b/circle-reactnative.appspot.com/o/circle-cup.png?alt=media&token=a6007a2e-183c-4681-bf1a-07ea637d1d2b"
                  height="50"
                  alt="Circle Logo"
                  />
                </div>

                <div>
                  <p class="payments-text-partyName">{this.state.partyName}</p>
                  <p class="payments-text-partySubtitle">Hosted by {this.state.partyHost} on {this.state.partyDate}</p>
                  <div class="testing-mode"><p class="payments-text-partySubtitle">TESTING MODE</p></div>
                </div>

                <div class="payments-view-wrapper">
                  <p class="payments-text-partyCost">${this.state.totalCost}</p>
                  <p class="payments-text-partySubtitle">${this.state.ticketPrice} ticket + ${this.state.ticketFees} fees</p>
                </div>

                <PayPalButton
                  amount={this.state.totalCost}
                  options={{
                    clientId: "Aex9S6-trh44V-0jN_urUGsll71gkqNHCW7-VpUZ9MhiK1Ee9-j79U3dw1OLkufWDN9PLSEaDjl8WW1g", //Switch once live
                    currency: "USD",
                    buyerCountry: "US", //Ideally turn this off but check first Venmo is running properly on mobile
                  }}
                  shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                  onSuccess={(details, data) => {
                    console.log(details)
                    console.log(data)

                    this.setState({loading:true});
                    this.markUserPaid(details);
                  }}
                />

              </Cell>
            )
          }
        }

      }
    }
  }

  render() {

    return(

      <div style={{height:"100%"}}>

          <Grid className="payments-grid">
            {this.renderContent()}
          </Grid>

      </div>


    )
  }
}

export default Payments;
