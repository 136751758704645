import React, { Component } from 'react';
import { Grid, Cell, Navigation } from 'react-mdl';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

ReactGA.initialize('UA-146114575-2');
ReactGA.pageview('/landing');

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

class Landing extends Component {

  onClickImage(){
    var link = "https://circle-parties.app.link/download";
    window.open(link, "_blank");
  }

  render() {

    var downloadLink = "https://circle-parties.app.link/download";

    const windowHeight = window.innerHeight;
    const carouselWidth = windowHeight * 0.46205357;

    return(
      <div className="height">

      <Grid className="landing-grid">
        <Cell col={12} className="main-image-wrapper">

        <a href={downloadLink}>
        <img
        src="https://firebasestorage.googleapis.com/v0/b/circle-website-4b5fb.appspot.com/o/Group%2021%402x.png?alt=media&token=84faba6f-84da-4bdf-9def-1f8548f6a0e0"
        alt="app-image"
        className="main-image"
        />
        </a>

        </Cell>
      </Grid>

          <Grid style={{width:"100%"}}>
            <Cell col={12} className="vertical">
            <div className="links-grid">
              <div className="links-gif-view">
                <a href={downloadLink}>
                <img
                src="https://firebasestorage.googleapis.com/v0/b/circle-website-4b5fb.appspot.com/o/circlewebicondark.png?alt=media&token=d390e30f-9130-43e1-a6a4-05428b0fe774"
                alt="solocup"
                className="links-gif"
                />
                </a>
              </div>
              <Navigation className="links-flex">
                  <a href={downloadLink}>download app</a>
                  <a href="mailto:support@circleparties.com">message us</a>
              </Navigation>
              <Navigation className="links-flex">
                  <a href="mailto:support@circleparties.com?subject=I've got an idea!&">submit ideas</a>
                  <Link to="/privacy">privacy</Link>
                  <Link to="/terms">terms</Link>
              </Navigation>
            </div>
            </Cell>
          </Grid>

      </div>


    )
  }
}

export default Landing;
