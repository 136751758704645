import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-146114575-2');
ReactGA.pageview('/download');

class Download extends Component {

  render() {

    return(

      <div style={{height:"100%"}}>

          <Grid className="landing-grid">
            <Cell col={12} className="vertical">
            <Redirect to='/'/>
            </Cell>
          </Grid>

      </div>


    )
  }
}

export default Download;
