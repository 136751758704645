import React from 'react';

export default class Loading extends React.Component {

  render() {

    return (
      <div>
        <img
        src="https://firebasestorage.googleapis.com/v0/b/circle-reactnative.appspot.com/o/circle-cup.png?alt=media&token=a6007a2e-183c-4681-bf1a-07ea637d1d2b"
        height="50"
        alt="Circle Logo"
        />
      <p>Loading</p>
      </div>
    );
  }
}
