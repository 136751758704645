import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import Select from 'react-select';
import { listOptions, groupedOptions } from './docs/data';

import ReactExport from "react-export-excel";

import moment from 'moment';
import Loading from './loader.js'

import CssBaseline from '@material-ui/core/CssBaseline'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';

import styled from 'styled-components'
import { useTable } from 'react-table'
import makeData from './makedata.js'

import app from 'firebase/app';
import firebase from 'firebase';

ReactGA.initialize('UA-146114575-2');
ReactGA.pageview('/download');

class GuestList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dataInvited: [{"name":"💀 No outstanding invites"}],
      dataAttending: [{"name":"👀 No one has joined the party yet"}],
      dataCheckedIn: [{"name":"💀 No one checked in so far"}],
      dataCheckedOut: [{"name":"💀 No one checked out so far"}],
      dataPaid: [{"name":"💰 No one has paid so far"}],
      dataNotPaid: [{"name":"🤑 No outstanding payments"}],
      dataInput: [""],
      columnsInput: [
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Paid',
          accessor: 'paid',
        },
      ],
    };
  }

  componentDidMount = async () => {
    await this.initializeFirebase();
    this.getPartyDetails();
  }


  initializeFirebase = async () => {
    const config = {
      apiKey: "AIzaSyBTDpAEjShenstwm3kytuuv4GKcfDlyjdg",
      authDomain: "circle-reactnative.firebaseapp.com",
      databaseURL: "https://circle-reactnative.firebaseio.com",
      projectId: "circle-reactnative",
      storageBucket: "circle-reactnative.appspot.com",
      messagingSenderId: "194697646030",
      appId: "1:194697646030:web:bd7a7b5a6a06616819259f",
      measurementId: "G-RZMFRTX2Z2"
    };
    await app.initializeApp(config);
  }

  getPartyDetails = async () => {
    //Get party details using partyId
    var partyId = this.props.match.params.party;
    var userId = this.props.match.params.user;

    const db = firebase.firestore();

    let partyRef = db.collection('parties').doc(partyId);
    let getDoc = partyRef.get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such party could be found!');
          this.setState({
            invalidParty:true,
            loading: false,
          })
        } else {
          console.log('Party found');

          if (doc.data().coverCharge){
            //Calculate the service fees and total cost
            //2.9% + $0.30 per sale + extra 5 cents
            var ticketPrice = Number((doc.data().ticketPrice).toFixed(2));
            var ticketFees = Number((ticketPrice * 0.029 + 0.30 + 0.02).toFixed(2));

            //Turn timestamp into a readable string
            var timestamp = doc.data().time;
            var partyTimeStringDate = moment(timestamp).format("ddd, MMM Do");
            var partyTimeStringTime = moment(timestamp).format("h:mm a");
            var partyTimeString = partyTimeStringDate + " at " + partyTimeStringTime;

            //Timestamp for party description
            var paymentTimeString = moment(timestamp).format("D MMM");

            //Generate payment description
            var partyTitle = doc.data().title;
            var paymentDescription = "Cover charge for " + partyTitle + " on " + paymentTimeString;

            this.setState({
              partyName: doc.data().title,
              partyHost: doc.data().host,
              paidParty: doc.data().coverCharge,
              ticketFees: ticketFees,
              ticketPrice: ticketPrice,
              totalCost: Number((ticketPrice + ticketFees).toFixed(2)),
              partyDate: partyTimeString,
              paymentDescription: paymentDescription,
            })

            this.getGuestlistData(doc.data().coverCharge);
          }else{
            //Turn timestamp into a readable string
            var timestamp = doc.data().time;
            var partyTimeStringDate = moment(timestamp).format("ddd, MMM Do");
            var partyTimeStringTime = moment(timestamp).format("h:mm a");
            var partyTimeString = partyTimeStringDate + " at " + partyTimeStringTime;

            //Timestamp for party description
            var paymentTimeString = moment(timestamp).format("D MMM");

            //Generate payment description
            var partyTitle = doc.data().title;
            var paymentDescription = "Cover charge for " + partyTitle + " on " + paymentTimeString;

            this.setState({
              partyName: doc.data().title,
              partyHost: doc.data().host,
              paidParty: doc.data().coverCharge,
              partyDate: partyTimeString,
              paymentDescription: paymentDescription,
            })

            this.getGuestlistData(doc.data().coverCharge);
          }

        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });
  }

  getGuestlistData = async (paidParty) => {
    //Get guest list data
    //GET PARTY details
    //THEN, if party is not a paid party, don't render the getPaid and getNotPaid lists

    if (paidParty){
      console.log("Is a paid party")
      await Promise.all([
        this.getInvited(), this.getAttending(), this.getCheckedin(), this.getCheckedout(), this.getPaid(), this.getNotPaid()
      ]);

      this.setState({loading:false})
    }else{
      console.log("Not a paid party")
      await Promise.all([
        this.getInvited(), this.getAttending(), this.getCheckedin(), this.getCheckedout()
      ]);

      this.setState({loading:false})
    }
  }

  getInvited = async () => {
    let result = await this.getPartyInvited();

    //Check if result is empty
    if (result.length === 0){
      this.setState({
        emptyInvited: true,
      });
    }else{
      this.setState({
        dataInvited: result,
        emptyInvited: false,
        countInvited: result.length,
      });
    }
  }

  getAttending = async () => {
    let result = await this.getPartyAttending();

    //Check if result is empty
    if (result.length === 0){
      this.setState({
        emptyAttending: true,
      });
    }else{
      this.setState({
        dataAttending: result,
        dataInput: result,
        emptyAttending: false,
        countAttending: result.length,
      });
    }
  }

  getCheckedin = async () => {
    let result = await this.getPartyCheckedIn();

    //Check if result is empty
    if (result.length === 0){
      this.setState({
        emptyCheckedIn: true,
      });
    }else{
      this.setState({
        dataCheckedIn: result,
        emptyCheckedIn: false,
        countCheckedIn: result.length,
      });
    }
  }

  getCheckedout = async () => {
    let result = await this.getPartyCheckedOut();

    //Check if result is empty
    if (result.length === 0){
      this.setState({
        emptyCheckedOut: true,
      });
    }else{
      this.setState({
        dataCheckedOut: result,
        emptyCheckedOut: false,
        countCheckedOut: result.length,
      });
    }
  }

  getPaid= async () => {
    let result = await this.getPartyPaid();

    //Check if result is empty
    if (result.length === 0){
      this.setState({
        emptyPaid: true,
      });
    }else{
      this.setState({
        dataPaid: result,
        emptyPaid: false,
        countPaid: result.length,
      });
    }
  }

  getNotPaid= async () => {
    let result = await this.getPartyNotPaid();

    //Check if result is empty
    if (result.length === 0){
      this.setState({
        emptyNotPaid: true,
      });
    }else{
      this.setState({
        dataNotPaid: result,
        emptyNotPaid: false,
        countNotPaid: result.length,
      });
    }
  }


  //MAIN FUNCTIONS TO GET AND RETURN LIST DATA ---------------------------------
  //Attending
  getPartyAttending = async () => {
    const party = this.props.match.params.party;
    const db = firebase.firestore();

    var userData = [];
    await db.collection("parties").doc(party).collection("attending")
      .orderBy("userName")
      .get()
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(user) {
              var paid;
              if (user.data().paid){
                paid = "Yes"
              }else{
                paid = "No"
              }
              userData.push({
                id: user.data().userId,
                name: user.data().userName,
                paid: paid,
              });
          });
      })
      .catch(function(error) {
          console.log("Error getting user list: ", error);
      });
      return userData
  }

  //Invited
  getPartyInvited = async () => {
    const party = this.props.match.params.party;
    const db = firebase.firestore();

    var userData = [];
    await db
    .collection("parties").doc(party)
    .collection("invited")
      .orderBy("userName")
      .get()
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(user) {
              userData.push({
                id: user.data().userId,
                name: user.data().userName,
              });
          });
      })
      .catch(function(error) {
          console.log("Error getting user list: ", error);
      });
      return userData
  }

  //Invite requests
  getPartyInviteRequests = async () => {
    const party = this.props.match.params.party;
    const db = firebase.firestore();

    var userData = [];
    await db.collection("parties").doc(party).collection("inviteRequests")
      .orderBy("userName")
      .get()
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(user) {
              var timestring = moment(user.data().time).format('MMM Do, LT');
              userData.push({
                id: user.data().userId,
                name: user.data().userName,
                time: timestring,
              });
          });
      })
      .catch(function(error) {
          console.log("Error getting user list: ", error);
      });
      return userData
  }

  //Checked in
  getPartyCheckedIn = async () => {
    const party = this.props.match.params.party;
    const db = firebase.firestore();

    var userData = [];
    await db.collection("parties").doc(party).collection("checkedIn")
      .orderBy("userName")
      .get()
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(user) {
            var timestring = moment(user.data().time).format('LT');
            var datestring = moment(user.data().time).format('MMM Do');
            userData.push({
              id: user.data().userId,
              name: user.data().userName,
              time: timestring,
              date: datestring,
            });
          });
      })
      .catch(function(error) {
          console.log("Error getting user list: ", error);
      });
      return userData
  }

  //Checked out
  getPartyCheckedOut = async () => {
    const party = this.props.match.params.party;
    const db = firebase.firestore();

    var userData = [];
    await db.collection("parties").doc(party).collection("checkedOut")
      .orderBy("userName")
      .get()
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(user) {
              var timestring = moment(user.data().time).format('LT');
              var datestring = moment(user.data().time).format('MMM Do');
              userData.push({
                id: user.data().userId,
                name: user.data().userName,
                time: timestring,
                date: datestring,
              });
          });
      })
      .catch(function(error) {
          console.log("Error getting user list: ", error);
      });
      return userData
  }

  //Not paid
  getPartyNotPaid = async () => {
    const party = this.props.match.params.party;
    const db = firebase.firestore();

    var userData = [];
    await db.collection("parties").doc(party).collection("attending")
      .where("paid", "==", false)
      .orderBy("userName")
      .get()
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(user) {
              userData.push({
                id: user.data().userId,
                name: user.data().userName,
                paid: "No"
              });
          });
      })
      .catch(function(error) {
          console.log("Error getting user list: ", error);
      });
      return userData
  }

  //Paid
  getPartyPaid = async () => {
    const party = this.props.match.params.party;
    const db = firebase.firestore();

    var userData = [];
    await db.collection("parties").doc(party).collection("attending")
      .where("paid", "==", true)
      .orderBy("userName")
      .get()
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(user) {
              var timestring = moment(user.data().paidTime).format('MMM Do, LT');
              userData.push({
                id: user.data().userId,
                name: user.data().userName,
                paidAmount: user.data().paidAmount || "Paid outside app",
                paidTime: timestring || null,
                paid: "Yes"
              });
          });
      })
      .catch(function(error) {
          console.log("Error getting user list: ", error);
      });
      return userData
  }


  //Selector
  handleChange = selectedOption => {
    this.setState(
      { selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption.value)
    );
    if (selectedOption.value === "invited"){
      this.setState({
        selectedOption,
        dataInput: this.state.dataInvited,
        columnsInput: [
          {
            Header: 'Name',
            accessor: 'name',
          },
        ]
      })
    }
    if (selectedOption.value === "attending"){
      this.setState({
        selectedOption,
        dataInput: this.state.dataAttending,
        columnsInput: [
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Paid',
            accessor: 'paid',
          },
        ]
      })
    }
    if (selectedOption.value === "checkedin"){
      this.setState({
        selectedOption,
        dataInput: this.state.dataCheckedIn,
        columnsInput: [
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Time',
            accessor: 'time',
          },
          {
            Header: 'Date',
            accessor: 'date',
          },
        ]
      })
    }
    if (selectedOption.value === "checkedout"){
      this.setState({
        selectedOption,
        dataInput: this.state.dataCheckedOut,
        columnsInput: [
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Time',
            accessor: 'time',
          },
          {
            Header: 'Date',
            accessor: 'date',
          },
        ]
      })
    }
    if (selectedOption.value === "paid"){
      this.setState({
        selectedOption,
        dataInput: this.state.dataPaid,
        columnsInput: [
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Paid',
            accessor: 'paid',
          },
          {
            Header: 'Amount ($)',
            accessor: 'paidAmount',
          },
        ]
      })
    }
    if (selectedOption.value === "notpaid"){
      this.setState({
        selectedOption,
        dataInput: this.state.dataNotPaid,
        columnsInput: [
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Paid',
            accessor: 'paid',
          },
        ]
      })
    }
  };

  renderLoading(){
    if(this.state.loading){
      return(
        <div className="guestlist-loading-wrapper">
          <div className="guestlist-loading">
              <Loading />
          </div>
        </div>
      )
    }else{
      //Set variables for table
      var dataInput = this.state.dataInput;
      var columnsInput = this.state.columnsInput;

      var dataInvited = this.state.dataInvited;
      var dataAttending = this.state.dataAttending;
      var dataCheckedIn = this.state.dataCheckedIn;
      var dataCheckedOut = this.state.dataCheckedOut;
      var dataPaid = this.state.dataPaid;
      var dataNotPaid = this.state.dataNotPaid;

      var partyName = this.state.partyName;
      var excelDocName = partyName + " - Guest List";

      //Selector Setup
      const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        justifyContent: 'space-between',
      };
      const textStyles = {
        textAlign: 'left',
      };
      const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 10,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
      };

      const formatGroupLabel = data => (
        <div style={groupStyles}>
          <span style={textStyles}>{data.label}</span>
          <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
      );

      //TABLE SETUP
      const Styles = styled.div`
        padding: 1rem;

        /* table {
          border-spacing: 0;
          border: 1px solid black;
          width: 100%;

          tr {
            :last-child {
              td {
                border-bottom: 0;
              }
            }
          }

          th,
          td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid black;
            border-right: 1px solid black;

            :last-child {
              border-right: 0;
            }
          }
        } */
      `

      function Table({ columns, data }) {
        // Use the state and functions returned from useTable to build your UI
        const {
          getTableProps,
          getTableBodyProps,
          headerGroups,
          rows,
          prepareRow,
        } = useTable({
          columns,
          data,
        })

        // Render the UI for your table
        return (
          <MaUTable {...getTableProps()}>
            <TableHead>
              {headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <TableCell {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                prepareRow(row)
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </MaUTable>
        )
      }

      function TableList() {
        //Conditional render depending on view type
        const columns = React.useMemo(
          () => columnsInput,
          []
        )

        const data = dataInput;

        return (
          <Styles>
            <Table columns={columns} data={data} />
          </Styles>
        )
      }

      //EXCEL EXPORT SETUP
      const ExcelFile = ReactExport.ExcelFile;
      const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
      const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

      return(

        <div style={{height:"100%"}}>

            <Grid className="guestlist-grid">
              <Cell col={6} className="guestlist-grid-cell">

                <a href="https://circle-parties.app.link/download">
                  <div className="payments-logo" style={{paddingTop:'40px'}}>
                    <img
                    src="https://firebasestorage.googleapis.com/v0/b/circle-reactnative.appspot.com/o/circle-cup.png?alt=media&token=a6007a2e-183c-4681-bf1a-07ea637d1d2b"
                    height="50"
                    alt="Circle Logo"
                    />
                  </div>
                </a>

                <div>
                  <p className="payments-text-partyName">{this.state.partyName}</p>
                  <p className="payments-text-partySubtitle">Hosted by {this.state.partyHost} on {this.state.partyDate}</p>
                </div>

                <div style={{padding:'20px'}}>
                  <Select
                    defaultValue={listOptions[1]}
                    options={groupedOptions}
                    formatGroupLabel={formatGroupLabel}
                    onChange={this.handleChange}
                    isSearchable={false}
                  />
                </div>

                <div style={{width:'100%'}}>
                  <TableList/>
                </div>

              </Cell>

              <ExcelFile
                element={
                  <div className="export-button-wrapper">
                    <div>
                      <Fab color="secondary" variant="extended" className="export-button" >
                        <GetAppIcon className="export-button-icon"/>
                        Download as spreadsheet
                      </Fab>
                    </div>
                  </div>
                }
                filename={excelDocName}
              >
                  <ExcelSheet data={dataInvited} name="Invited">
                      <ExcelColumn label="Name" value="name"/>
                  </ExcelSheet>
                  <ExcelSheet data={dataAttending} name="Attending">
                      <ExcelColumn label="Name" value="name"/>
                      <ExcelColumn label="Paid" value="paid"/>
                  </ExcelSheet>
                  <ExcelSheet data={dataCheckedIn} name="Checked In">
                      <ExcelColumn label="Name" value="name"/>
                      <ExcelColumn label="Time" value="time"/>
                      <ExcelColumn label="Date" value="date"/>
                  </ExcelSheet>
                  <ExcelSheet data={dataCheckedOut} name="Checked Out">
                      <ExcelColumn label="Name" value="name"/>
                      <ExcelColumn label="Time" value="time"/>
                      <ExcelColumn label="Date" value="date"/>
                  </ExcelSheet>
                  <ExcelSheet data={dataPaid} name="Paid">
                      <ExcelColumn label="Name" value="name"/>
                      <ExcelColumn label="Paid" value="paid"/>
                      <ExcelColumn label="Amount" value="paidAmount"/>
                  </ExcelSheet>
                  <ExcelSheet data={dataNotPaid} name="Not Paid">
                      <ExcelColumn label="Name" value="name"/>
                      <ExcelColumn label="Paid" value="paid"/>
                  </ExcelSheet>
              </ExcelFile>

            </Grid>

        </div>


      )
    }
  }

  render() {

    return(
      <div>
        {this.renderLoading()}
      </div>
    )
  }
}

export default GuestList;
